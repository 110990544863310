import { Control, Controller } from "react-hook-form";
import { TFormData } from "../types";

type TProps = {
  control: Control<TFormData>;
  name: keyof TFormData;
  label: string;
  disabled?: boolean;
};

export default function Checkbox({ control, name, label, disabled }: TProps) {
  return (
    <div>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <>
            <input
              type="checkbox"
              id={`checkbox-${name}`}
              className="hidden peer"
              checked={!!field.value}
              disabled={disabled}
              onChange={(e) => field.onChange(e.target.checked)}
              ref={field.ref}
            />
            <label
              htmlFor={`checkbox-${name}`}
              className="inline-flex items-center justify-center w-full p-2 text-sm font-medium text-center bg-white border rounded-lg cursor-pointer text-slate-600 border-slate-600 peer-checked:border-slate-600 peer-checked:bg-slate-600 hover:text-white peer-checked:text-white hover:bg-slate-500"
            >
              {label}
            </label>
          </>
        )}
      />
    </div>
  );
}