import { useEffect, useRef } from 'react';
import hljs from 'highlight.js';
import 'highlight.js/styles/atom-one-light.css';

interface CodeBlockProps {
  code: string;
}

const CodeBlock: React.FC<CodeBlockProps> = ({ code }) => {

  const codeRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (codeRef.current) {
      const detected = hljs.highlightAuto(code);
      if (detected.language) {
        hljs.highlightElement(codeRef.current);
      }
    }
  }, [code]);

  return (
    <pre>
      <code 
        ref={codeRef} 
        className="whitespace-pre-wrap hljs"
      >
        {code}
      </code>
    </pre>
  );
};

export default CodeBlock;